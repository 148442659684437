import iconv from 'iconv-lite'
import { EEncoding } from '@/_declarations/enums/EEncoding'
import { MEDIA_TYPES } from '@/helpers/mediaTypes'

const getBlobUTF8 = (body: BlobPart, mimeType: MEDIA_TYPES) =>
  new Blob([body], { type: `${mimeType};charset=${EEncoding.UTF8}` })

const getBlobWin1251 = (body: string, mimeType: MEDIA_TYPES) => {
  const byteArrayWin1251 = iconv.encode(body, EEncoding.WIN1251)

  return new Blob([byteArrayWin1251], { type: `${mimeType};charset=windows-1251` })
}

export const getBlob = (body: BlobPart, mimeType: MEDIA_TYPES, encoding: EEncoding) => {
  switch (encoding) {
    case EEncoding.UTF8: return getBlobUTF8(body, mimeType)
    case EEncoding.WIN1251: return getBlobWin1251(body as string, mimeType)
    default: throw new Error('Invalid encoding')
  }
}
